
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PublicService } from 'app/services/public.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from 'app/services/models/user';
import { UserBaseService } from 'app/services/user-base.service';

@Injectable()
export class UserStdService extends UserBaseService {

  constructor(public publicService: PublicService, public httpClient: HttpClient) {
    super(publicService, httpClient);
  }

  protected getBasePath(): string {
    return super.getBasePath() + "Std/";
  }
  protected getaaBasePath(): string {
    return super.getaBasePath() + "listOperaris/";
  }
  protected getzzBasePath(): string {
    return super.getaBasePath() + "uniqueOperPartes/";
  }
  protected getzxBasePath(): string {
    return super.getaBasePath() + "uniqueAlbarans/";
  }
  protected getzyBasePath(): string {
    return super.getaBasePath() + "uniqueProvs/";
  }
  protected getzwBasePath(): string {
    return super.getaBasePath() + "getUserList/";
  }
  protected getabBasePath(): string {
    return super.getaBasePath() + "newOperari/";
  }
  protected getacBasePath(): string {
    return super.getaBasePath() + "getUser/";
  }
  protected getacdasePath(): string {
    return super.getaBasePath() + "updateUser/";
  }
  protected getaeBasePath(): string {
    return super.getaBasePath() + "deleteUser/";
  }
  protected getafBasePath(): string {
    return super.getaBasePath() + "sendConfirmationEmail/";
  }
  protected getagBasePath(): string {
    return super.getaBasePath() + "checkEmail/";
  }
  protected getahBasePath(): string {
    return super.getaBasePath() + "getToken/";
  }
  protected getaiBasePath(): string {
    return super.getaBasePath() + "sendRecoverMail/";
  }
  protected getajBasePath(): string {
    return super.getaBasePath() + "newOperariControl/";
  }
  protected getadBasePath(): string {
    return super.getaBasePath() + "updateUserControl/";
  }
  public putUserStd(id: string, data){
    let url = this.getacdasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+id+'","email":"'+data.email+'","rol":"'+data.rol+'","actiu":"'+data.isActive+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public putUserStdControl(id: string, data){
    let url = this.getadBasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+id+'","email":"'+data.email+'","rol":"'+data.rol+'","actiu":"'+data.isActive+'","name":"'+data.operariControl+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public postUserStd(dbname,dbuser,dbpass,codiOperari,rol,email,userId){
    let url = this.getabBasePath();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","codiOperariG2":"'+codiOperari+'","email":"'+email+'","rol":"'+rol+'","userId":"'+userId+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public postUserStdControl(dbname,dbuser,dbpass,nomOperari,rol,email,userId){
    let url = this.getajBasePath();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","nomOperari":"'+nomOperari+'","email":"'+email+'","rol":"'+rol+'","userId":"'+userId+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public checkEmail(email){
    let url = this.getagBasePath();
    var body = JSON.parse('{"email":"'+email+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public getToken(email){
    let url = this.getahBasePath();
    var body = JSON.parse('{"email":"'+email+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public sendConfirmation(email,token){
    let url= this.getafBasePath();

    var body = JSON.parse('{"email":"'+email+'","token":'+token+'}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public resendPassword(email,token){
    let url= this.getaiBasePath();

    var body = JSON.parse('{"email":"'+email+'","token":'+token+'}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public getUser(id:string):Observable<User>{
    let url= this.getBasePath() + id;
    return this.httpClient.get<User>(url,{headers:this.getHeaders()});
  }

  public getUser2(userId){
    let url= this.getacBasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+userId+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public deleteUserStd(id){
    let url= this.getaeBasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'","userId":"'+id+'"}');
    let head = this.getHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(url, body, { headers: head, responseType: 'text' });
  }

  public getOperaris(dbname,dbuser,dbpass){
    let url= this.getaaBasePath();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'"}');
    return this.httpClient.post(url, body);
  }
  public getUniqueOper(){
    let url= this.getzzBasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'"}');
    return this.httpClient.post(url, body);
  }

   public getUserList(){
    let url= this.getzwBasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'"}');
    return this.httpClient.post(url, body);
  }

  public getUniqueProvs(){
    let url= this.getzyBasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'"}');
    return this.httpClient.post(url, body);
  }

  public getUniqueAlbs(){
    let url= this.getzxBasePath();
    var dbname = this.publicService.getDBName();
      var dbuser = this.publicService.getDBUser();
      var dbpass = this.publicService.getDBPass();
    var body = JSON.parse('{"dbname":"'+dbname+'","dbuser":"'+dbuser+'","dbpass":"'+dbpass+'"}');
    return this.httpClient.post(url, body);
  }

  public uploadUsers(data): Observable<string> {
      let url = this.getBasePath() + "excel/";
      return this.httpClient.post(url, "aaa", { headers: this.getHeaders(), responseType: 'text' });
  }

  public getUsersExcel() {

    let url = this.getBasePath() + "ExcelUsr/";
    const type = 'application/vnd.ms-excel';

    return this.httpClient.get(url, {
      headers: this.getHeaders(),
      responseType: 'blob'
    }).pipe(map(
      res => {

        return new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      }));

  } 
  //public getFiles(id: string) {
  //    let url = this.getBasePath() + "files/"+id;
  //    return this.httpClient.get<User>(url, { headers: this.getHeaders() });
  //}
}
